<template>
  <div class="transition duration-200 p-5 bg-black/10 backdrop-blur-sm fixed h-full w-full left-0 top-0  flex justify-center items-center z-50 pointer-events-auto">
    <pv-progress-spinner
      class="w-36 h-36"
      stroke-width="3"
      :pt="{
        circle: '!stroke-primary-300',
      }"
      :pt-options="{ mergeProps: true }"
    />
  </div>
</template>
