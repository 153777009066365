<script setup lang="ts">
import { ref } from 'vue'
import {
  Dialog,
  DialogPanel,
  Popover,
  PopoverButton,
  PopoverPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'

import data from '@/definitions/moduleDefinitions'
import { useAccountApi } from '@/composables/api/accountApi'
import { useResourcesApi } from '@/composables/api/resourcesApi'
import type { OfficeI } from '@/types/quotes'
import { useAuthStore } from '@/stores/authStore'

const { fetchAccountData, switchCustomer } = useAccountApi()
const { signIn: loginCustomer, data: user } = useAuth()
const { t } = useI18n()
const { errorToast } = useToast()
const queryClient = useQueryClient()
const { getOffices } = useResourcesApi()
const { signOut } = useSignOut()
const route = useRoute()
const { isRouteAllowed } = useHierarchicalPermissions()
const authStore = useAuthStore()

const active = ref('')
const mobileMenuOpen = ref(false)
const searchVisible = ref(false)
const contactVisible = ref(false)

const { data: userData, isFetching: loadingData } = useQuery({
  queryKey: ['fetchAccountData'],
  queryFn: async () => {
    return await fetchAccountData()
  },
  select: ({ data }) => data.user,
})

const fullName = computed(() =>
  userData.value?.contact
    ? `${userData.value.contact?.name} ${userData.value.contact?.lastName}`
    : '',
)

const parentItems = computed(() => {
  return data.filter(item => !!item.group)
})
const activeChildItems = computed(() => {
  if (!route.meta?.group) {
    return []
  }

  return data.filter(item => item.parent === route.meta?.group) ?? []
})

const customerId = computed(() => user.value?.customer?.id)
const { isFetching: loadingCustomers, data: customerOptions } = useQuery({
  queryKey: ['fetchCustomers'],
  queryFn: async () => {
    return await getOffices()
  },
  select: ({ data }) => {
    return (
      data.map((office: OfficeI) => ({
        label: office.name,
        value: office.id,
      })) ?? []
    )
  },
})

// customer-switch
const switchingCustomer = ref(false)

async function changeCustomer(data: {
  accessToken: string
  refreshToken: string
}) {
  const response = await loginCustomer('refresh', {
    accessToken: data.accessToken,
    refreshToken: data.refreshToken,
    redirect: false,
  })
  if (response.error) {
    errorToast(t('notify.error.other'))
  }

  await authStore.updateCurrentToken()
  queryClient.invalidateQueries()
  switchingCustomer.value = false
}

const { mutateAsync: handleCustomerChange } = useMutation({
  mutationFn: async (data: { customer_id: string }) =>
    await switchCustomer(data),
  onSuccess: ({ data }) => {
    changeCustomer(data)
  },
  onError: () => {
    switchingCustomer.value = false
  },
})

function onCustomerChange(id: string) {
  if (!id) {
    return
  }

  mobileMenuOpen.value = false
  switchingCustomer.value = true
  handleCustomerChange({ customer_id: id })
}

async function onSignOut() {
  await signOut({ redirect: false })
  navigateTo('/login')
}

function goTo(item: Partial<NavigationItemI>) {
  if (item.group === 'dashboard') {
    return '/secure'
  }

  return `/secure/${item.to || item.group}`
}
</script>

<template>
  <!-- Top nav -->
  <header class="relative h-20 flex items-center bg-white">
    <!-- Logo area -->
    <div class="absolute inset-y-0 left-0 flex-shrink-0 md:hidden">
      <a
        href="#"
        class="flex h-20 w-20 items-center justify-center bg-primary-800 focus:outline-none md:w-20"
      >
        <layout-logo class="h-12 w-auto fill-white" />
      </a>
    </div>

    <!-- Picker area -->
    <div class="mx-auto lg:hidden">
      <Popover class="relative">
        <PopoverButton
          class="inline-flex items-center gap-x-1 font-semibold leading-6 text-gray-900"
        >
          <span class="capitalize text-2xl">{{ active ?? $t("header.menu") }}</span>
          <Icon
            name="heroicons:chevron-down-20-solid"
            class="w-6 h-6"
            aria-hidden="true"
          />
        </PopoverButton>

        <transition
          enter-active-class="transition ease-out duration-200"
          enter-from-class="opacity-0 translate-y-1"
          enter-to-class="opacity-100 translate-y-0"
          leave-active-class="transition ease-in duration-150"
          leave-from-class="opacity-100 translate-y-0"
          leave-to-class="opacity-0 translate-y-1"
        >
          <PopoverPanel
            class="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4"
          >
            <div
              class="w-screen max-w-md flex-auto overflow-hidden rounded bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5"
            >
              <div v-if="activeChildItems.length">
                <h2 class="text-center pt-7 text-lg uppercase font-semibold">
                  {{ route.meta.group }}
                </h2>
                <div class="p-4">
                  <div
                    v-for="item in activeChildItems"
                    :key="item.name"
                    class="group relative flex gap-x-6 rounded-lg p-4  hover:bg-slate-100 hover:bg-opacity-80 text-slate-500"
                    :class="{ 'bg-slate-100 border border-gray-200 !text-black': route.path === goTo(item) }"
                  >
                    <div
                      class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg"
                    >
                      <Icon
                        :name="item.icon"
                        class="h-7 w-7 "
                        aria-hidden="true"
                      />
                    </div>
                    <div>
                      <NuxtLink
                        v-if="isRouteAllowed(item.to || item.group)"
                        :to="goTo(item)"
                        class="font-semibold  capitalize"
                      >
                        {{ item.name }}
                        <span class="absolute inset-0" />
                      </NuxtLink>
                      <p class="mt-1">
                        {{ item.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="px-2 pt-4">
                <FormKit
                  v-if="!loadingCustomers && customerOptions.length > 1"
                  type="dropdown"
                  :options="customerOptions"
                  inner-class=" bg-white w-full"
                  :placeholder="t('header.switch-customer')"
                  :value="customerId"
                  @input="onCustomerChange"
                />
              </div>
              <div
                class="grid grid-cols-4 divide-x divide-gray-900/5 bg-gray-50"
              >
                <button
                  class="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                  @click="searchVisible = true"
                >
                  <Icon
                    name="ap:search-lg"
                    class="h-6 w-6 flex-none text-gray-400"
                    aria-hidden="true"
                  />
                </button>
                <button
                  class="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                  @click="contactVisible = true"
                >
                  <Icon
                    name="ap:message-circle-02"
                    class="h-6 w-6 flex-none text-gray-400"
                    aria-hidden="true"
                  />
                </button>
                <NuxtLink
                  to="/secure/account/profile"
                  class="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                >
                  <Icon
                    name="ap:user-01"
                    class="h-5 w-5 flex-none text-gray-400"
                    aria-hidden="true"
                  />
                </NuxtLink>
                <button
                  class="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                  @click="onSignOut"
                >
                  <Icon
                    name="ap:log-out-02"
                    class="h-5 w-5 flex-none text-gray-400"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </PopoverPanel>
        </transition>
      </Popover>
    </div>

    <!-- Menu button area -->
    <div
      class="absolute inset-y-0 right-0 flex items-center pr-4 sm:pr-6 md:hidden"
    >
      <!-- Mobile menu button -->
      <button
        type="button"
        class="-mr-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-400"
        @click="mobileMenuOpen = true"
      >
        <span class="sr-only">{{ $t("header.open-menu") }}</span>
        <Icon
          name="ap:menu-01"
          class="w-7 h-7 text-gray-400 hover:text-gray-500"
        />
      </button>
    </div>

    <!-- Desktop nav area -->
    <div
      class="hidden lg:flex lg:min-w-0 lg:flex-1 lg:items-center lg:justify-between bg-white h-20 px-4 sm:px-6 lg:pl-8"
    >
      <layout-breadcrumbs @update:active="active = $event" />
      <div class="flex mt-1 items-center gap-4 mr-4">
        <pv-button
          v-pvtooltip.bottom="{
            value: t('header.global-search'),
          }"
          text
          outlined
          severity="secondary"
          @click="searchVisible = true"
        >
          <Icon
            name="ap:search-lg"
            class="w-6 h-6 [&>path]:stroke-[3]"
          />
        </pv-button>
        <pv-button
          v-pvtooltip.bottom="{
            value: t('header.contact'),
          }"
          text
          outlined
          severity="secondary"
          @click="contactVisible = true"
        >
          <Icon
            name="ap:message-circle-02"
            class="w-6 h-6 [&>path]:stroke-[3]"
          />
        </pv-button>
        <NuxtLink to="/secure/create">
          <pv-button
            v-pvtooltip.bottom="{
              value: t('request.project-quote'),
            }"
            aria-label="Request"
            label=""
            outlined
            severity="secondary"
            size="small"
          >
            <div class="flex gap-2 items-center">
              <Icon
                name="ap:folder-plus"
                class="w-6 h-6 [&>path]:stroke-[3] text-primary"
              />
            </div>
          </pv-button>
        </NuxtLink>
        <FormKit
          v-if="!loadingCustomers && customerOptions.length > 1"
          type="dropdown"
          :options="customerOptions"
          inner-class="!mb-0 bg-white !min-w-[12rem]"
          outer-class="!min-w-[18rem]"
          :placeholder="t('header.switch-customer')"
          :value="customerId"
          @input="onCustomerChange"
        />
      </div>
    </div>

    <!-- Mobile menu, show/hide this `div` based on menu open/closed state -->
    <TransitionRoot
      as="template"
      :show="mobileMenuOpen"
    >
      <Dialog
        as="div"
        class="relative z-40 lg:hidden"
        @close="mobileMenuOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div
            class="hidden sm:fixed sm:inset-0 sm:block sm:bg-gray-600 sm:bg-opacity-75"
          />
        </TransitionChild>

        <div class="fixed inset-0 z-40">
          <TransitionChild
            as="template"
            enter="transition ease-out duration-150 sm:ease-in-out sm:duration-300"
            enter-from="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
            enter-to="transform opacity-100 scale-100  sm:translate-x-0 sm:scale-100 sm:opacity-100"
            leave="transition ease-in duration-150 sm:ease-in-out sm:duration-300"
            leave-from="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
            leave-to="transform opacity-0 scale-110  sm:translate-x-full sm:scale-100 sm:opacity-100"
          >
            <DialogPanel
              class="fixed inset-0 z-40 h-full w-full bg-white xs:inset-y-0 xs:left-auto xs:right-0 xs:max-w-sm xs:shadow-lg"
              aria-label="Global"
            >
              <div class="flex h-full text-gray-500 max-w-8xl flex-col justify-between">
                <div class="">
                  <div class="flex justify-end mt-3 px-4 sm:px-6">
                    <button
                      type="button"
                      class="-mr-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-400"
                      @click="mobileMenuOpen = false"
                    >
                      <span class="sr-only">{{ $t("header.close-menu") }}</span>
                      <Icon
                        name="ap:x-close"
                        class="w-7 h-7 text-gray-400 hover:text-gray-500"
                      />
                    </button>
                  </div>

                  <div class="mt-3 px-6">
                    <div class="flex gap-3 items-center ">
                      <account-profile-picture
                        :picture="userData?.picture"
                        :name="fullName"
                        size="small"
                        :is-loading="loadingData"
                      />

                      <div class="-mb-1">
                        <div class="truncate text-lg font-semibold text-black">
                          {{ fullName }}
                        </div>
                        <FormKit
                          v-if="!loadingCustomers && customerOptions.length > 1"
                          type="dropdown"
                          :options="customerOptions"
                          listitem-class="p-1"
                          option-class=" !p-0"
                          selector-class="!min-h-1"
                          inner-class="!ring-0 !mb-0 !text-md !text-gray-500"
                          dropdown-wrapper-class="!my-0 "
                          :value="customerId"
                          @input="onCustomerChange"
                        />
                      </div>
                      <a
                        href="#"
                        class="ml-auto flex-shrink-0 bg-white p-2 text-gray-400 hover:text-gray-500"
                      >
                        <span class="sr-only">{{
                          $t("header.view-notification")
                        }}</span>
                      </a>
                    </div>
                  </div>
                  <div class="pt-10 px-4 flex flex-col gap-2">
                    <template
                      v-for="item in parentItems"
                      :key="item.name"
                    >
                      <NuxtLink
                        v-if="isRouteAllowed(item.to || item.group)"
                        :to="goTo(item)"
                        class="flex gap-3 items-center rounded-md py-3 px-3 capitalize text-lg font-medium hover:bg-slate-100 hover:bg-opacity-70 text-slate-500"
                        active-class="bg-slate-100 border border-gray-200 !text-black"
                        @click="mobileMenuOpen = false"
                      >
                        <Icon
                          :name="item.icon"
                          class="w-6 h-6 mb-1"
                          aria-hidden="true"
                        />
                        {{ item.name }}
                      </NuxtLink>
                    </template>
                  </div>
                </div>
                <div class="mb-7 text-slate-500 px-4 flex flex-col gap-2 cursor-pointer ">
                  <NuxtLink
                    to="/secure/account/security"
                    class="flex items-center gap-2 rounded-md py-3 px-2 hover:bg-slate-100/80"
                    active-class="bg-slate-100 border border-gray-200 !text-black"
                    @click="mobileMenuOpen = false"
                  >
                    <Icon
                      name="ap:settings-02"
                      class="w-6 mb-1 h-6"
                      aria-hidden="true"
                    />
                    <span
                      class="block rounded-md text-base font-medium"
                    >{{ $t("shared.settings") }}</span>
                  </NuxtLink>
                  <div
                    class="flex items-center gap-2  text-red-600  hover:text-red-500 hover:bg-slate-100/80  rounded-md py-3 px-2"
                  >
                    <Icon
                      name="ap:log-out-02"
                      class="w-6 mb-1 h-6"
                      aria-hidden="true"
                    />
                    <span
                      class="block rounded-md text-base font-medium "
                      @click="onSignOut"
                    >{{ $t("auth.logout") }}</span>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
    <global-search
      v-model:visible="searchVisible"
      @hide="searchVisible = false"
    />

    <pv-dialog
      v-model:visible="contactVisible"
      modal
      :header="t('shared.actions.send-message')"
      class="!w-1/3"
    >
      <div>
        <contact-form @cancel="contactVisible = false" />
      </div>
    </pv-dialog>

    <loader-blur-spinner v-if="switchingCustomer" />
  </header>
</template>

<style scoped>
</style>
