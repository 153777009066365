<script setup lang="ts">
import {
  EventSourcePolyfill,
  NativeEventSource,
} from 'event-source-polyfill'
import { useToast } from 'primevue/usetoast'

const route = useRoute()
const { data: authData } = useAuth()
const config = useRuntimeConfig()
const toastVisible = ref(false)
const { t } = useI18n()
const activeGroup = computed(() => {
  return route.meta?.group ?? 'dashboard'
})
const mercureToast = useToast()
const router = useRouter()
const queryClient = useQueryClient()

const loading = ref(true)

onBeforeMount(() => {
  loading.value = true
})

onMounted(() => {
  loadMercure()
  loading.value = false
})

function loadMercure() {
  const EventSource
      = NativeEventSource || EventSourcePolyfill
  const userId = authData.value?.user.id
  const url = `${config.public.mercureUrl}/${userId}`

  const es = new EventSource(url, {
    withCredentials: true,
  })
  es.onerror = es.onopen = function (event) {
    // console.log(event.type, event);
  }

  es.onmessage = function (event) {
    const data = JSON.parse(event.data)
    const isProject = data?.entityType === 'project'
    const redirectPath = `/secure/${isProject ? 'projects' : 'quotes'}/${data.entityId}`
    const message = t('notify.source-files-processed', { id: data.idNumber })
    mercureToast.add({ severity: 'info', summary: message, group: 'mercure', detail: redirectPath, life: 10000 })
    queryClient.invalidateQueries({ queryKey: [isProject ? 'fetchProject' : 'fetchQuote', data.entityId] })
    toastVisible.value = true
  }
}

function onCloseToast(redirectPath: string = '') {
  mercureToast.removeGroup('mercure')
  toastVisible.value = false
  if (redirectPath && redirectPath !== route.fullPath)
    router.push({ path: redirectPath })
}
</script>

<template>
  <div
    class="flex w-full h-full top-0"
  >
    <layout-loader v-if="loading" />
    <template v-else>
      <layout-sidebar :current-module="activeGroup" />

      <div
        class="flex min-w-0 flex-1 flex-col
      grow md:ml-16"
      >
        <main class="flex flex-1">
          <!-- Secondary sidebar -->
          <layout-second-sidebar :current-module="activeGroup" />

          <!-- Main content -->
          <!-- <div class="flex-1 xl:overflow-y-auto bg-slate-100 w-full"> -->
          <div
            class="flex-1 bg-gray-100 w-full"
            :style="{ 'background-color': '#F3F3F3 !important' }"
          >
            <layout-header />
            <div
              id="sub-layout-header"
              class="sticky top-0 z-30"
            />
            <div class="inset-0 px-4 sm:px-6 lg:px-8 mb-12 mt-6">
              <div class="h-full py-4 w-full">
                <slot />
              </div>
            </div>
          </div>
        </main>
      </div>
    </template>
    <pv-toast
      position="top-right"
      group="mercure"
      @close="onCloseToast"
    >
      <template #message="slotProps">
        <div>
          <div class="font-medium text-lg my-3 text-900">
            {{ slotProps.message.summary }}
          </div>
          <pv-button
            v-if="slotProps.message.detail !== route.fullPath"
            severity="info"
            label="Take me there"
            size="small"
            @click="onCloseToast(slotProps.message.detail)"
          />
        </div>
      </template>
    </pv-toast>
  </div>
</template>

<style>

</style>
