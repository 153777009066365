<script setup lang="ts">
import modules from '@/definitions/moduleDefinitions'

const props = defineProps({
  currentModule: {
    type: String,
    default: '',
  },
})

const { isRouteAllowed } = useHierarchicalPermissions()

const sidebarOpen = ref(true)

const childItems = computed(() => {
  return modules.filter(item => item.parent === props.currentModule) ?? []
})

watch(() => props.currentModule, () => {
  sidebarOpen.value = true
})

function formattedPath(item: NavigationItemI) {
  return `/secure/${item.to}`
}
</script>

<template>
  <ClientOnly>
    <nav
      v-if="childItems.length"
      aria-label="Sections"
      class="z-10 hidden w-60 flex-shrink-0 border-r border-slate-200 bg-white lg:flex lg:flex-col transition-all duration-300 relative"
      :class="{ '-ml-56': !sidebarOpen }"
    >
      <div class="flex h-20 flex-shrink-0 items-center justify-between border-b border-slate-200 ">
        <p class="text-lg font-bold text-slate-700 px-6 capitalize">
          {{ currentModule }}
        </p>
        <button
          class="p-2 z-50"
          :class="{ '-right-12 absolute': !sidebarOpen, 'relative': sidebarOpen }"
          @click="sidebarOpen = !sidebarOpen"
        >
          <Icon
            :name="`${sidebarOpen ? 'heroicons:chevron-left-20-solid' : 'heroicons:chevron-right-20-solid'}`"
            class="text-gray-700 h-7 w-7 rounded-full hover:rounded-xl transition-all duration-200 ease-linear cursor-pointer hover:ring-2 hover:ring-yellow-600 hover:ring-offset-1"
            :class="{ 'hover:bg-gray-700 text-slate-700 hover:text-slate-200': !sidebarOpen }"
            aria-hidden="true"
          />
        </button>
      </div>
      <div class="min-h-0 flex flex-col overflow-y-auto p-4 gap-4">
        <template
          v-for="item in childItems"
          :key="item.name"
        >
          <NuxtLink
            v-if="isRouteAllowed(item.to || item.group)"
            :to="formattedPath(item)"
            class="flex p-4 hover:bg-slate-100 hover:bg-opacity-80 text-slate-500 rounded-lg"
            active-class="bg-slate-100 border border-gray-200 !text-black"
          >
            <div class="flex flex-row grow">
              <Icon
                :name="item.icon"
                class="w-7 h-7"
              />
              <div class="w-full pl-2">
                <p class="capitalize text-md font-semibold font-lg">
                  {{ item.name }}
                </p>
                <p class=" text-sm text-slate-500 font-light tracking-tight leading-normal ">
                  {{ item.description }}
                </p>
              </div>
            </div>
          </NuxtLink>
        </template>
      </div>
    </nav>
  </ClientOnly>
</template>

<style scoped>

</style>
