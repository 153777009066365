<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 50"
    class=""
  >
    <g transform="matrix(.07042 0 0 -.0699 7.796 9.113)">
      <path d="M0 0c26.453 0 47.898 21.444 47.898 47.898 0 26.453-21.445 47.897-47.898 47.897-26.453 0-47.898-21.444-47.898-47.897C-47.898 21.444-26.453 0 0 0" />
    </g>
    <g transform="matrix(.07042 0 0 -.0699 11.819 25.085)">
      <path d="M0 0c.138.02.201.188.1.284-4.246 3.998-60.462 59.048-29.961 143.459 12.228 31.248 38.232 44.179 42.221 46.009a.157.157 0 0 1-.048.302c-6.603.787-69.599 6.915-118.072-38.942C-164.062 95.956-122.787-17.419 0 0" />
    </g>
    <g transform="matrix(.07042 0 0 -.0699 32.372 41.23)">
      <path d="M0 0c-26.453 0-47.898-21.444-47.898-47.897 0-26.454 21.445-47.898 47.898-47.898 26.453 0 47.897 21.444 47.897 47.898C47.897-21.444 26.453 0 0 0" />
    </g>
    <g transform="matrix(.07042 0 0 -.0699 28.365 25.257)">
      <path d="M0 0c-.227-.032-.335-.306-.169-.464 5.3-5.049 59.975-59.816 29.805-143.311-11.916-30.452-36.917-43.508-41.874-45.847a.266.266 0 0 1 .083-.508c8.341-.935 70.034-6.098 117.69 38.987C163.801-96.021 122.612 17.248 0 0" />
    </g>
    <g transform="matrix(.07042 0 0 -.0699 12.44 0)">
      <path d="M0 0c-.635.006-.76-.91-.145-1.07 64.176-16.747 58.741-69.974 59.477-80.659v-183.484c0-37.221 13.832-57.549 29.45-71.301 14.3-12.593 32.527-19.701 51.47-20.888v-.462c-18.943-1.187-37.17-8.295-51.47-20.887-15.618-13.753-29.45-34.081-29.45-71.301v-183.485c-.736-10.685 4.699-63.911-59.477-80.659-.615-.16-.49-1.076.145-1.07 25.151.25 156.601 7.601 156.601 134.048v223.553h-.046v.065h.046v223.552C156.601-7.6 25.151-.249 0 0" />
    </g>
    <g transform="matrix(.07042 0 0 -.0699 39.027 4.58)">
      <path d="M0 0h-149.864c-7.631 0-13.817-6.186-13.817-13.816v-.001c0-7.63 6.186-13.816 13.817-13.816h122.231c7.631 0 13.816-6.186 13.816-13.817v-146.456c0-7.63 6.186-13.816 13.817-13.816 7.63 0 13.816 6.186 13.816 13.816v174.09C13.816-6.186 7.63 0 0 0" />
    </g>
    <g transform="matrix(.07042 0 0 -.0699 11.526 44.153)">
      <path d="M0 0h-122.231c-7.631 0-13.817 6.186-13.817 13.816v146.457c0 7.63-6.185 13.816-13.816 13.816-7.631 0-13.817-6.186-13.817-13.816v-174.09c0-7.63 6.186-13.816 13.817-13.816H0c7.631 0 13.816 6.186 13.816 13.816C13.816-6.186 7.631 0 0 0" />
    </g>
  </svg>
</template>
