<script setup>
const props = defineProps({
  tooltip: {
    type: String,
    default: () => null,
  },
  icon: {
    type: String,
    default: () => null,
  },
  badge: {
    type: String,
    default: () => null,
  },
  selected: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['click'])
</script>

<template>
  <pv-button
    v-pvtooltip.right="{
      value: props.tooltip,
      pt: {
        root: 'mx-4 px-1 absolute',
      },
    }"
    :pt="{
      root: { class: '' },
    }"
    class="relative focus-visible:outline-none flex items-center justify-center h-11 w-11 mt-2 mb-2 mx-auto dark:bg-gray-800 hover:rounded-lg rounded-3xl transition-all duration-300 ease-linear cursor-pointer active:scale-95 group"
    :class="{ 'rounded-lg bg-primary-dark': selected }"
    @click="emit('click', $event)"
  >
    <Icon
      v-if="props.icon"
      :name="props.icon"
      class="w-7 h-7 text-white"
    />
    <div
      v-if="props.badge"
      class="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-700 rounded-full -top-0 right-1 dark:border-gray-900"
    >
      {{ props.badge }}
    </div>
    <slot class="w-7 h-7" />
  </pv-button>
</template>
