<script setup>
const emit = defineEmits(['update:active'])
// const pageTitle = useState('pageTitle')
const route = useRoute()
const { t } = useI18n()

const last = ref()

watch(last, (newLast) => {
  emit('update:active', newLast)
})

const crumbs = computed(() => {
  const fullPath = route.path
  const params = fullPath.startsWith('/secure/')
    ? fullPath.substring(1).split('/')
    : fullPath.split('/')

  const crumbs = []
  let path = '/secure'

  if (!params || params.length === 0)
    return crumbs

  params.forEach((param, index) => {
    if (param === 'secure' || param === '') {
      last.value = t(`shared.home`)
      return
    }
    // if param is an id return
    if (/\d/.test(param))
      return

    path = `${path}/${param}`
    const title = t(`pages.${param}.title`)

    let visitable = true
    if (index === params.length - 1) {
      visitable = false
      last.value = title
    }

    crumbs.push({
      title,
      path,
      visitable,
    })
  })
  return crumbs
})

// const paramTitle = computed(() => {
//   let title = '\xA0'

//   if (route.params.id && pageTitle.value)
//     title = pageTitle.value

//   if (route?.meta?.title)
//     title = route.meta.title
//   return title
// })
</script>

<template>
  <nav
    class="flex"
    aria-label="Breadcrumb"
  >
    <ol class="inline-flex items-center space-x-1 md:space-x-3">
      <li class="inline-flex items-center">
        <NuxtLink
          property="item"
          typeof="WebPage"
          to="/secure"
          class="inline-flex items-center text-lg font-medium text-gray-500 hover:text-primary dark:text-gray-400 dark:hover:text-white"
        >
          {{ $t('shared.home') }}
        </NuxtLink>
      </li>
      <li
        v-for="(crumb, index) in crumbs"
        :key="index"
        property="itemListElement"
        typeof="ListItem"
      >
        <div class="flex items-center">
          <Icon
            name="ap:chevron-right"
            class="w-5 h-5 text-gray-400 "
          />
          <div class="ml-1 capitalize text-lg font-semibold text-gray-700 md:ml-2 dark:text-gray-400 dark:hover:text-white">
            <NuxtLink
              v-if="crumb.visitable"
              property="item"
              typeof="WebPage"
              :to="crumb.path"
              class=" hover:text-primary"
            >
              {{ crumb.title }}
            </NuxtLink>
            <span
              v-else
              class="cursor-default"
            >
              {{ crumb.title }}
            </span>
          </div>

          <meta
            property="position"
            :content="index + 2"
          >
        </div>
      </li>
      <li
        id="breadcrumbs-id-number"
      />
    </ol>
  </nav>
</template>
