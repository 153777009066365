<script>
export default {
  data() {
    return {
      preset: 'avantui',
      presetOptions: [
        { name: 'AvantUi', value: 'avantui' },
        { name: 'Lara', value: 'lara' },
        { name: 'Aura', value: 'aura' },
      ],
      primaryColors: [
        {
          name: 'yellow',
          palette: [
            '253 247 230',
            '251 238 204',
            '247 221 153',
            '242 204 102',
            '238 187 51',
            '234 170 0',
            '187 136 0',
            '140 102 0',
            '94 68 0',
            '47 34 0',
          ],
        },
        {
          name: 'green',
          palette: [
            '232 241 241',
            '208 227 227',
            '162 199 200',
            '115 170 172',
            '69 142 145',
            '22 114 117',
            '18 91 94',
            '13 68 70',
            '9 46 47',
            '4 23 23',
          ],
        },
        {
          name: 'blue',
          palette: [
            '185 217 231',
            '143 193 215',
            '105 169 199',
            '77 152 190',
            '51 136 182',
            '40 123 170',
            '26 107 154',
            '16 91 137',
            '0 64 106',
            '2 44 34',
          ],
        },
      ],
      surfaces: [
        {
          name: 'gray',
          palette: ['255 255 255', '249 250 251', '243 244 246', '229 231 235', '209 213 219', '156 163 175', '107 114 128', '75 85 99', '55 65 81', '31 41 55', '17 24 39', '8 8 8'],
        },
        {
          name: 'slate',
          palette: ['255 255 255', '248 250 252', '241 245 249', '226 232 240', '203 213 225', '148 163 184', '100 116 139', '71 85 105', '45 55 72', '30 41 59', '15 23 42', '3 6 23'],
        },
        {
          name: 'zinc',
          palette: ['255 255 255', '250 250 250', '244 244 245', '228 228 231', '212 212 216', '161 161 170', '113 113 122', '82 82 91', '63 63 70', '39 39 42', '24 24 27', '18 18 19'],
        },
        {
          name: 'neutral',
          palette: ['255 255 255', '250 250 250', '245 245 245', '229 229 229', '212 212 212', '163 163 163', '115 115 115', '82 82 82', '64 64 64', '38 38 38', '24 24 24', '17 17 17'],
        },
        {
          name: 'stone',
          palette: ['255 255 255', '250 250 249', '245 245 244', '231 229 228', '214 211 209', '168 162 158', '120 113 108', '87 83 78', '68 64 60', '46 42 41', '36 33 31', '28 25 23'],
        },
        {
          name: 'surface',
          palette: [
            '255 255 255',
            '243 247 252',
            '232 238 250',
            '208 221 245',
            '185 204 239',
            '161 187 234',
            '138 170 229',
            '110 136 183',
            '83 102 137',
            '55 68 92',
            '28 34 46',
          ],
        },
      ],
    }
  },
  computed: {
    isLara() {
      return this.$appState.preset === 'lara'
    },
    isWind() {
      return this.$appState.preset === 'wind'
    },
  },
  methods: {
    updateColors(type, colors) {
      if (!document.startViewTransition) {
        this.applyTheme(type, colors)

        return
      }

      document.startViewTransition(() => this.applyTheme(type, colors))
    },
    toggle(event) {
      this.$refs.op.toggle(event)
    },
    applyTheme(type, colors) {
      let increments

      if (type === 'primary')
        increments = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900]
      else if (type === 'surface')
        increments = [0, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900]

      colors.forEach((color, index) => {
        document.documentElement.style.setProperty(`--${type}-${increments[index]}`, color)
      })
    },
    setPreset(preset) {
      this.$appState.preset = preset
    },
    setRipple(value) {
      const app = document.getElementById('app')

      app.classList.toggle('layout-ripple-disabled')
      this.$primevue.config.ripple = value
    },
  },
}
</script>

<template>
  <div>
    <a
      class="none"
      @click="toggle"
    >
      <layout-side-button
        tooltip="configurator"
        aria-haspopup="true"
        aria-controls="configurator_menu"
        icon="heroicons:swatch"
        class="hover:bg-primary-dark"
      />
    </a>
    <pv-popover ref="op">
      <div
        class="w-[19rem] p-3 bg-white dark:bg-surface-800 rounded-md shadow border border-surface-200 dark:border-surface-700 flex-col justify-start items-start gap-3.5 inline-flex origin-bottom z-10"
      >
        <div class="flex-col justify-start items-start gap-2 inline-flex w-full">
          <span class="text-black dark:text-surface-0 text-sm font-medium">Preset</span>
          <pv-select-button
            v-model="preset"
            option-label="name"
            option-value="value"
            :options="presetOptions"
            aria-labelledby="presets"
            @update:model-value="setPreset($event)"
          />
        </div>
        <div class="flex-col justify-start items-start gap-2 inline-flex pr-4">
          <span class="text-black dark:text-surface-0 text-sm font-medium">Primary Colors</span>
          <div class="self-stretch justify-start items-start gap-2 inline-flex flex-wrap">
            <pv-button
              v-for="primaryColor of primaryColors"
              :key="primaryColor.name"
              type="button"
              class="w-4 h-4 rounded-full cursor-pointer"
              :style="{ backgroundColor: `rgb(${primaryColor.palette[5]})` }"
              @click="updateColors('primary', primaryColor.palette)"
            />
          </div>
        </div>
        <div class="flex-col justify-start items-start gap-2 inline-flex pr-2">
          <span class="text-black dark:text-surface-0 text-sm font-medium">Surface Colors</span>
          <div class="self-stretch justify-start items-start gap-2 inline-flex">
            <pv-button
              v-for="surface of surfaces"
              :key="surface.name"
              type="button"
              class="w-4 h-4 rounded-full cursor-pointer"
              :style="{ backgroundColor: `rgb(${surface.palette[6]})` }"
              @click="updateColors('surface', surface.palette)"
            />
          </div>
        </div>
      </div>
    </pv-popover>
  </div>
</template>
