<script setup lang="ts"></script>

<template>
  <div class="absolute z-10 flex justify-center items-center w-full h-full">
    <img
      src="~assets/spinners/ap_spinner_blue.svg"
      alt="generating"
      class="w-7/12 mx-auto"
    >
  </div>
</template>
